import './SttDictionaries.css'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import jschardet from "jschardet"
import Encoding from "encoding-japanese"
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  createUserSttDictionaries,
  deleteUserSttDictionaries,
  getSystemSttDictionaries,
  getUserSttDictionaries,
  updateUserSttDictionaries,
  SttDictionariesFirebaseData
} from "../../../api/MeetingFirebaseApi"
import { getParentReplaceKeywords } from "../../../api/MeetingApi";
import UserInfo from "../../../store/UserInfo"

export const SttDictionaries = observer(() => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('')
  const [data, setData] = useState<SttDictionariesFirebaseData[]>([])
  const [editingId, setEditingId] = useState<string | null>(null)

  const [parents, setParents] = useState<SttDictionariesFirebaseData[]>([])
  const [filteredParents, setFilteredParents] = useState<SttDictionariesFirebaseData[]>([])

  const defaultRef = useRef<SttDictionariesFirebaseData[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const newKeywordRef = useRef<HTMLInputElement>(null)
  const editKeywordRef = useRef<HTMLInputElement>(null)

  const existSystemSttDictionaries = async (keyword: string) => {
    const systemSttDictionaries = await getSystemSttDictionaries()
    return systemSttDictionaries.some(dict => dict.targetWord === keyword)
  }

  const existUserSttDictionaries = async (keyword: string, excludeId?: string) => {
    const userSttDictionaries = await getUserSttDictionaries(UserInfo.id)
    return userSttDictionaries.some(dict =>
        dict.targetWord === keyword && dict.id !== excludeId
    )
  }

  const existParentSttDictionaries = async (keyword: string) => {
    return parents.some(dict =>
        dict.targetWord === keyword
    )
  }

  const checkValidKeyword = async (keyword: string, excludeId?: string) => {
    if (await existSystemSttDictionaries(keyword)) {
      return { isValid: false, error: t('管理者によって設定されています。この言葉を使用することはできません。') }
    }
    if (await existUserSttDictionaries(keyword, excludeId)) {
      return { isValid: false, error: t('重複があります。同じ言葉を使用することはできません。') }
    }
    if (await existParentSttDictionaries(keyword)) {
      return { isValid: false, error: t('共通辞書に重複があります。同じ言葉を使用することはできません。') }
    }
    return { isValid: true }
  }

  const onClickUploadButton = () => {
    fileInputRef.current?.click()
  }

  const readFileContent = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = async (event: ProgressEvent<FileReader>) => {
        try {
          const arrayBuffer = event.target?.result
          if (!arrayBuffer) {
            return reject("ArrayBuffer is null or undefined")
          }

          const uint8Array = new Uint8Array(arrayBuffer as ArrayBuffer)
          const binaryString = new TextDecoder("iso-8859-1").decode(uint8Array)

          // 文字コードを判定
          const detected = jschardet.detect(binaryString)
          let detectedEncoding = detected?.encoding?.toLowerCase() || "utf-8"

          // windows-1252 を Shift-JIS にフォールバック
          if (detectedEncoding === "windows-1252") {
            detectedEncoding = "shift-jis"
          }

          let content: string
          try {
            if (detectedEncoding === "shift-jis") {
              const unicodeArray = Encoding.convert(uint8Array, {
                from: "SJIS",
                to: "UNICODE",
                type: "array",
              })
              content = Encoding.codeToString(unicodeArray)
            } else {
              content = new TextDecoder(detectedEncoding).decode(uint8Array)
            }
          } catch (e) {
            content = new TextDecoder("utf-8").decode(uint8Array)
          }

          resolve(content)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = (error) => reject(error)
      reader.readAsArrayBuffer(file)
    })
  }

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!fileInputRef.current?.files?.[0]) return

    const file = fileInputRef.current.files[0]

    try {
      const content = await readFileContent(file)
      const keywords = content.split(/\r?\n/)
          .map(line => line.trim())
          .filter(line => line !== '')

      const errors: string[] = []
      await Promise.all(keywords.map(async (keyword, index) => {
        const result = await checkValidKeyword(keyword)
        if (!result.isValid) {
          errors.push(`${index + 1}${t('行目')}: ${keyword} - ${result.error}`)
        }
      }))

      if (errors.length > 0) {
        alert(errors.join('\n'))
        event.target.value = ''
        return
      }

      // エラーがない場合のみ保存処理を実行
      for (const keyword of keywords) {
        await createUserSttDictionaries(UserInfo.id, { targetWord: keyword })
      }

      await fetchAndFilterData('')  // キーワード検索をクリアして全件表示
      alert(t('取り込みが完了しました'))
      event.target.value = ''
    } catch (error) {
      console.error('Error:', error)
      alert(t('エラーが発生しました。再度お試しください。'))
      event.target.value = ''
    }
  }

  const addNewKeyword = async () => {
    const newWord = newKeywordRef.current?.value.trim()
    if (!newWord) return

    const result = await checkValidKeyword(newWord)
    if (!result.isValid) {
      alert(result.error)
      return
    }

    await createUserSttDictionaries(UserInfo.id, { targetWord: newWord })
    await fetchAndFilterData('')  // キーワード検索をクリアして全件表示
    if (newKeywordRef.current) newKeywordRef.current.value = ''
    setKeyword('')  // 検索キーワードをクリア
  }

  const startEditing = (id: string, keyword: string) => {
    setEditingId(id)
    setEditingId(id)
    setTimeout(() => {
      if (editKeywordRef.current) {
        editKeywordRef.current.value = keyword
        editKeywordRef.current.focus()
      }
    }, 0)
  }

  const saveEdit = async (id: string) => {
    const newKeyword = editKeywordRef.current?.value.trim()
    if (!newKeyword) return

    const result = await checkValidKeyword(newKeyword, id)
    if (!result.isValid) {
      alert(result.error)
      return
    }

    const item = data.find(d => d.id === id)
    if (item) {
      await updateUserSttDictionaries(UserInfo.id, {
        ...item,
        targetWord: newKeyword
      })
      await fetchAndFilterData(keyword)  // 現在の検索状態を維持
    }
    setEditingId(null)
  }

  const cancelEdit = () => {
    setEditingId(null)
  }

  const deleteKeyword = async (id: string) => {
    if (window.confirm(t('本当に削除しますか？'))) {
      await deleteUserSttDictionaries(UserInfo.id, id)
      await fetchAndFilterData(keyword)
    }
  }

  const onChangeSearchKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.currentTarget.value)
  }

  const fetchAndFilterData = useCallback(async (searchKeyword: string) => {
    const dictionaries = await getUserSttDictionaries(UserInfo.id)
    defaultRef.current = dictionaries
    if (!searchKeyword) {
      setFilteredParents(parents);
      setData(dictionaries)
      return
    }
    const filtered = dictionaries.filter(dict =>
        dict.targetWord.toLowerCase().includes(searchKeyword.toLowerCase())
    )
    setData(filtered)
    if (parents.length !== 0) {
      const filtered = parents.filter(dict =>
          dict.targetWord.toLowerCase().includes(searchKeyword.toLowerCase())
      )
      setFilteredParents(filtered);
    }
  }, [parents]);

  useEffect(() => {
    getParentReplaceKeywords().then(res => {
      if (res.data.success && res.data.dictionaries && res.data.dictionaries.length !== 0) {
        setParents(res.data.dictionaries);
        setFilteredParents(res.data.dictionaries);
      }
    })
  }, []);

  useEffect(() => {
    fetchAndFilterData(keyword)
  }, [fetchAndFilterData, keyword])

  return (
      <div className='container p-4'>
        <div className='row mb-3'>
          <div className='col-auto h1 text-primary mb-0'>
            <b>{t('辞書登録')}</b>
          </div>
          <div className='col text-donut'>
            {t('キーワードを入力して追加してください')}
          </div>
        </div>

        <div className="row mb-3 g-3">
          <div className="col-2">
            <button type="button"
                    className="btn btn-purple text-light w-100"
                    onClick={onClickUploadButton}>
              {t('一括取込')}
            </button>
            <input ref={fileInputRef}
                   onChange={onChangeFile}
                   className="d-none"
                   accept='text/csv, text/plain'
                   type="file"/>
          </div>
          <div className="col-10">
            <div className="input-group">
              <div className="input-group-text">
                <i className="bi bi-search"></i>
              </div>
              <input type="text"
                     className="form-control"
                     onChange={onChangeSearchKeyword}
                     value={keyword}
                     placeholder={t('登録済の辞書を検索...')}/>
            </div>
          </div>
        </div>

        {filteredParents.length > 0 ? (
            <>
              <h5>共通辞書</h5>
              <div className="list-group parent-dictionary-list-container dictionary-list-container mb-3">
                {filteredParents.map((item) => (
                    <div key={item.id} className="list-group-item list-group-item-secondary">
                      <div className="row align-items-center">
                        <div className="col">
                          <span>{item.targetWord}</span>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </>
        ) : (
            <>
              {parents.length !== 0 ? (
                  <div className="list-group-item text-center text-muted">
                    {t('検索結果が見つかりません')}
                  </div>
              ) : ''}
            </>
        )}

        <div className="list-group-item mb-3">
          <div className="row g-3">
            <div className="col">
              <input ref={newKeywordRef}
                     type="text"
                     className="form-control"
                     placeholder={t('新しいキーワードを入力...')}/>
            </div>
            <div className="col-auto">
              <button className="btn btn-primary"
                      onClick={addNewKeyword}>
                {t('追加')}
              </button>
            </div>
          </div>
        </div>
        {data.length > 0 ? (
            <div className="list-group dictionary-list-container">
              {data.map((item) => (
                  <div key={item.id} className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col">
                        {editingId === item.id ? (
                            <input
                                ref={editKeywordRef}
                                type="text"
                                className="form-control"
                                defaultValue={item.targetWord}
                            />
                        ) : (
                            <span>{item.targetWord}</span>
                        )}
                      </div>
                      <div className="col-auto">
                        {editingId === item.id ? (
                            <>
                              <button className="btn btn-success btn-sm me-2"
                                      onClick={() => saveEdit(item.id!)}>
                                {t('保存')}
                              </button>
                              <button className="btn btn-secondary btn-sm"
                                      onClick={cancelEdit}>
                                {t('キャンセル')}
                              </button>
                            </>
                        ) : (
                            <>
                              <button className="btn btn-primary btn-sm me-2"
                                      onClick={() => startEditing(item.id!, item.targetWord)}>
                                {t('編集')}
                              </button>
                              <button className="btn btn-danger btn-sm"
                                      onClick={() => deleteKeyword(item.id!)}>
                                {t('削除')}
                              </button>
                            </>
                        )}
                      </div>
                    </div>
                  </div>
              ))}
            </div>
        ) : (
            <div className="list-group-item text-center text-muted">
              {keyword ? t('検索結果が見つかりません') : t('登録されているキーワードがありません')}
            </div>
        )}
      </div>
  )
})
