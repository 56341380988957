import { makeAutoObservable } from 'mobx'
import {
  SHARE_SCOPE_TYPE,
  getMeetingMessages,
  updateMeetingMessageText,
  deleteMeetingMessage,
  updateMeetingMessageCustomUserName,
  updateMeetingSpeakerUserName,
  getMeeting,
  updateMeetingSpeakers,
  updateMeetingNumberOfSpeakers,
} from '../../api/MeetingFirebaseApi';
import { type ILanguages } from '../../constant/Languages'
import i18next from 'i18next';
import { getNextWeekDate } from '../../util/date';
import UserInfo from '../UserInfo';

const formatDate = (date: Date) => {
  return `${date.getFullYear()}${i18next.t('date.year')} ${date.getMonth() + 1} ${i18next.t('date.month')} ${date.getDate()} ${i18next.t('date.day')}`
}

export function timestampToDate(something: any): Date {
  if (something instanceof Date) {
    return something
  } else if (typeof something === 'object' && something != null && 'seconds' in something) {
    return new Date(something.seconds * 1000)
  } else if (typeof something === 'object' && something != null && '_seconds' in something) {
    return new Date(something._seconds * 1000)
  } else if (typeof something === 'string') {
    return new Date(something)
  }

  throw new Error("This cannot be considered time")
}

export enum MINUTE {
  'summary' = 'summary',
  'agenda' = 'agenda',
}

export const summaryAgenda: string = 'AI要約';
export const minutesAgenda: string = '議事録';
export const initialSummary: string = '議事内容を定期的に要約します。';
export const initialMinutes: string = '議題に沿った議事録を作成します。';

export type ChatMessage = {
  id: string
  userId: string | number
  userName?: string
  language: string
  messages: { [lang: string]: string }
  createdAt?: Date
  updatedAt?: Date
  speakerId?: string
  audioUrl?: string
  enableCustomUserName?: boolean;
  customUserName?: string;
}

export type DisplayChatMessage = {
  id: string;
  userId: string;
  speakerId: string;
  userName: string;
  enableCustomUserName: boolean;
  customUserName: string;
  user: string;
  messages: { [lang: string]: string };
  createdAt?: Date;
  audioUrl?: string;
}

export type Participant = {
  clientId: string
  id: string
  isAddedByUser: boolean
  name: string
  state: string
}

export type Speaker = {
  id: string
  userId: string | null
  username?: string
}

export interface RealtimeMinute {
  agenda: string;
  type: MINUTE;
  markdown: string;
  minutes?: string[];
  concerns?: string[];
  pending?: string[];
  decisions?: string[];
  next?: string[];
  summary?: string[];
  next_step?: string[];
}

export enum MeetingStates {
  BUILDING = 'building',
  WAITING = 'waiting',
  INCALL = 'incall',
  FINISH = 'finish',
  COMPLETED = 'completed',
};

export enum SummaryStatus {
  NONE = 'none',
  PROCESSING = 'process',
  COMPLETED = 'completed',
  ERRORED = 'errored',
}

const PREVIEW_MESSAGE_AMOUNT = 10

export class Meeting {
  /**
   * Get all messages in a specified language. If no language is specified get all messages in all languages.
   */
  static getAllMessages = (meeting: Meeting, language?: string, fetchedMessages: ChatMessage[] = []) => {
    let messages = (meeting.messages || fetchedMessages);
    let speakers = (meeting.speakers || []);
    const list: DisplayChatMessage[] = [];

    // スピーカー情報のマップを作成
    const speakerMap = new Map(
      speakers.map(s => [s.id, s])
    );

    const currentLangPrefix = i18next.language.split("-")[0];

    for (const msg of messages) {
      if (!msg.messages || (language && !msg.messages[language])) continue;

      // 言語に応じたメッセージの選択
      const msgObj = language ? { [language]: msg.messages[language] } : msg.messages;
      const keys = Object.keys(msgObj);

      let sortedMessages: { [key: string]: string };
      if (keys.length === 1) {
        sortedMessages = msgObj;
      } else {
        sortedMessages = {};
        const sortedKeys = keys.sort((a, b) => {
          const aIsCurrentLang = a.startsWith(currentLangPrefix);
          const bIsCurrentLang = b.startsWith(currentLangPrefix);
          return aIsCurrentLang ? -1 : bIsCurrentLang ? 1 : a.localeCompare(b);
        });
        for (const key of sortedKeys) {
          sortedMessages[key] = msgObj[key];
        }
      }

      const isSystem = !msg.userId;
      let displayName = msg.userName ? msg.userName : UserInfo.displayName;
      if (msg.enableCustomUserName) {
        displayName = msg.customUserName || '';
      } else {
        if (!isSystem) {
          const speaker = msg.userId && msg.speakerId ? speakerMap.get(`${msg.userId}/${msg.speakerId.split('/').length > 1 ? msg.speakerId.split('/')[1] : msg.speakerId}`) : undefined;
          if (speaker && speaker.username != null && speaker.username !== '') {
            displayName = `${speaker.username}`;
          } else if (msg.speakerId) {
            displayName = `${displayName}(${msg.speakerId.split('/').length > 1 ? msg.speakerId.split('/')[1] : msg.speakerId})`;
          } else {
            displayName = `${displayName}`;
          }
        } else if (isSystem && displayName) {
          displayName = i18next.t(displayName);
        }
      }

      list.push({
        id: msg.id,
        userId: msg.userId?.toString() ?? '',
        speakerId: msg.speakerId ?? '',
        userName: msg.userName ?? '',
        enableCustomUserName: msg.enableCustomUserName ?? false,
        customUserName: msg.customUserName ?? '',
        user: displayName,
        messages: sortedMessages,
        createdAt: msg.createdAt,
        audioUrl: msg.audioUrl,
      });
    }
    return list;
  };

  id: string
  owner?: string | number
  title: string
  state: string
  token?: string
  startTime?: string
  messages?: ChatMessage[]
  languages?: ILanguages[]
  summaryStatus: SummaryStatus
  summaryInstantStatus: SummaryStatus
  summary?: string
  beginAt?: Date
  endAt?: Date
  scheduledBeginAt?: Date
  scheduledEndAt?: Date
  allocatedTime?: number // in seconds.
  createdAt: string
  updatedAt: string
  additionalDocument?: string
  realtimeMinutes?: RealtimeMinute[]
  participants?: Participant[]
  speakers?: Speaker[]
  numberOfSpeakers: number = 0
  fullAudioUrl?: string
  hasUploadedFiles?: boolean
  shareInfo?: {
    expiry: number,
    scope: SHARE_SCOPE_TYPE,
    emails: string[]
  }
  tags: string[] = [] // Tags associated with the meeting
  joinUrl?: string

  private fetching = false
  // private setFetching = (fetching = true) => {
  //   this.fetching = fetching
  // }

  private fullyFetched = false

  // Returns in Seconds.
  get timeElapsed(): number | null {
    if (this.beginAt == null || this.endAt == null) {
      return null
    }
    return (this.endAt.getTime() - this.beginAt.getTime()) / 1000
  }

  constructor(id: string, data: any) {
    this.id = id
    this.owner = data.owner
    this.title = data.title
    this.state = data.state

    this.summaryStatus = SummaryStatus.NONE
    this.summaryInstantStatus = SummaryStatus.NONE
    if (Object.values(SummaryStatus).includes(data.summaryStatus)) {
      this.summaryStatus = data.summaryStatus;
    }

    this.summary = data.summary

    this.token = data.token || ''
    this.startTime = data.startTime || ''
    this.allocatedTime = data.allocatedTime || 0

    // computed values
    // this.messages = data.messages
    // this.languages = data.languages

    const createdAt = new Date(data.createdAt.seconds * 1000)
    const updatedAt = new Date(data.updatedAt.seconds * 1000)
    if (data.beginAt) {
      this.beginAt = timestampToDate(data.beginAt)
    }
    if (data.endAt) {
      this.endAt = timestampToDate(data.endAt)
    }

    if (data.scheduledBeginAt) {
      this.scheduledBeginAt = new Date(data.scheduledBeginAt);
    }
    if (data.scheduledEndAt) {
      this.scheduledEndAt = new Date(data.scheduledEndAt);
    }

    this.createdAt = formatDate(createdAt)
    this.updatedAt = formatDate(updatedAt)

    if (data.speakers) {
      this.speakers = data.speakers;
    }
    if (data.numberOfSpeakers) {
      this.numberOfSpeakers = data.numberOfSpeakers;
    } else if (this.speakers && this.speakers.length) {
      this.numberOfSpeakers = this.speakers.length;
    }

    if (data.participants) {
      this.participants = data.participants;
    }

    if (data.additionalDocument) {
      this.additionalDocument = data.additionalDocument;
    }

    if (data.realtimeMinutes) {
      this.realtimeMinutes = data.realtimeMinutes.filter((r: { markdown: string; }) => r.markdown !== initialSummary && r.markdown !== initialMinutes);
    }
    if (this.realtimeMinutes?.length === 0 && this.summary) {
      this.realtimeMinutes.push({
        agenda: summaryAgenda,
        type: MINUTE.summary,
        markdown: this.summary
      })
    }

    if (data.fullAudioUrl) {
      this.fullAudioUrl = data.fullAudioUrl;
    }

    this.hasUploadedFiles = !!data?.hasUploadedFiles

    if (data.shareInfo) {
      this.shareInfo = {
        expiry: data.shareInfo.expiry,
        scope: data.shareInfo.scope,
        emails: data.shareInfo.emails,
      }
    } else {
      this.shareInfo = {
        expiry: getNextWeekDate().getTime(),
        scope: SHARE_SCOPE_TYPE.private,
        emails: [],
      }
    }

    if (data.tags && Array.isArray(data.tags)) {
      this.tags = data.tags;
    }

    if (data.joinUrl) {
      this.joinUrl = data.joinUrl;
    }

    makeAutoObservable(this)
  }

  setData = (data: any) => {
    Object.assign(this, data)

    if (data.beginAt) {
      this.beginAt = timestampToDate(data.beginAt)
    }
    if (data.endAt) {
      this.endAt = timestampToDate(data.endAt)
    }
    if (data.createdAt) {
      const createdAt = new Date(data.createdAt.seconds * 1000)
      this.createdAt = formatDate(createdAt)
    }
    if (data.updatedAt) {
      const updatedAt = new Date(data.updatedAt.seconds * 1000)
      this.updatedAt = formatDate(updatedAt)
    }

    if (data.joinUrl) {
      this.joinUrl = data.joinUrl;
    }

    if (Object.values(SummaryStatus).includes(data.summaryStatus)) {
      this.summaryStatus = data.summaryStatus;
      if (this.summaryStatus !== SummaryStatus.PROCESSING && this.summaryInstantStatus === SummaryStatus.PROCESSING) {
        this.summaryInstantStatus = SummaryStatus.NONE
      }
    }

    if (this.summary) {
      if (!this.realtimeMinutes) {
        this.realtimeMinutes = [];
      }
      const index = this.realtimeMinutes?.findIndex(r => r.type === MINUTE.summary);
      if (index === -1) {
        this.realtimeMinutes.push({
          agenda: summaryAgenda,
          type: MINUTE.summary,
          markdown: this.summary
        })
      } else if (this.realtimeMinutes && this.realtimeMinutes[index]) {
        this.realtimeMinutes[index].markdown = this.summary;
      }
    }

    if (this.realtimeMinutes) {
      this.realtimeMinutes = this.realtimeMinutes.filter((r: { markdown: string; }) => r.markdown !== initialSummary && r.markdown !== initialMinutes);
    }
    if (this.messages && (!this.speakers || this.speakers.length === 0)) {
      this.speakers = Array.from(
        new Map(
          this.messages.map(m => { return [`${m.userId}/${m.speakerId}`, { id: `${m.userId}/${m.speakerId}`, userId: null }]; })
        ).values());
      updateMeetingSpeakers(this.owner, this.id, this.speakers);
    }
  }

  fetchMessages = async (fullyFetch: boolean = false) => {
    if (this.fetching || this.fullyFetched) {
      return
    }
    if (this.messages != null && !fullyFetch) {
      return
    }

    if (this.owner) {
      this.fetching = true
      let messagesSnap = null

      // TODO need revisit
      if (fullyFetch) {
        messagesSnap = await getMeetingMessages(this.owner, this.id)
      } else {
        messagesSnap = await getMeetingMessages(this.owner, this.id, PREVIEW_MESSAGE_AMOUNT)
      }

      // collect messages
      const messages: ChatMessage[] = []
      // collect languages
      const lang: { [language: string]: boolean } = {}

      messagesSnap.docs.forEach((doc) => {
        const msgData = doc.data() as ChatMessage
        if (msgData.createdAt) {
          msgData.createdAt = timestampToDate(msgData.createdAt)
        }
        if (msgData.updatedAt) {
          msgData.updatedAt = timestampToDate(msgData.updatedAt)
        }

        messages.push({
          ...msgData,
          id: doc.id,
        })

        Object.keys(msgData.messages || {}).forEach((l) => lang[l] = true)
      })

      this.setData({
        messages: messages,
        languages: Object.keys(lang),
        // status
        fetching: false,
        fullyFetched: fullyFetch || (messages.length < PREVIEW_MESSAGE_AMOUNT)
      })
    }
  }

  fetchSpeakers = async () => {
    if (this.fetching || this.fullyFetched) {
      return
    }

    if (this.owner) {
      this.fetching = true
      const doc = await getMeeting(this.owner, this.id);
      const data = doc.data();
      if (!data || !data.messages) {
        return;
      }
      this.setData({
        speakers: data.speakers,
      })
    }
  }

  updateMessageText = async (message: ChatMessage) => {
    await updateMeetingMessageText(this.owner, this.id, message.id, message.messages);
    await this.fetchMessages(true);
  }

  updateMessageCustomUserName = async (id: string, userName: string, enabled: boolean, speakerId: string) => {
    await updateMeetingMessageCustomUserName(this.owner, this.id, id, userName, enabled, speakerId);
    this.fullyFetched = false;
    await this.fetchMessages(true);
    if (this.messages) {
      this.numberOfSpeakers = Array.from(
        new Map(
          this.messages.map(m => {
            if (m.enableCustomUserName) {
              return [m.customUserName, m.customUserName];
            }
            return [`${m.userId}/${m.speakerId}`, `${m.userId}/${m.speakerId}`];
          })
        ).values()).length;
      await updateMeetingNumberOfSpeakers(this.owner, this.id, this.numberOfSpeakers);
    }
  }

  updateSpeakerUserName = async (id: string, userName: string) => {
    await updateMeetingSpeakerUserName(this.owner, this.id, id, userName);
    await this.fetchSpeakers();
    if (this.messages) {
      this.numberOfSpeakers = Array.from(
        new Map(
          this.messages.map(m => {
            if (m.enableCustomUserName) {
              return [m.customUserName, m.customUserName];
            }
            return [`${m.userId}/${m.speakerId}`, `${m.userId}/${m.speakerId}`];
          })
        ).values()).length;
      await updateMeetingNumberOfSpeakers(this.owner, this.id, this.numberOfSpeakers);
    }
  }

  deleteMessage = async (message_id: string) => {
    await deleteMeetingMessage(this.owner, this.id, message_id);
    await this.fetchMessages(true);
  }
}
