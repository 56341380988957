import { type MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

type InputProps = JSX.IntrinsicElements['input']
interface SettingRowProps extends InputProps {
  title: string
  label?: string
  choices?: { label: string, value: string }[],
  onClick?: (e: MouseEvent) => any,
}

export const SettingRow = ({ title, label, value, choices, type, onChange, onClick, disabled, ...props }: SettingRowProps) => {
  const { t } = useTranslation()

  return (
    <div className={`row align-items-center ${onClick ? 'mt-3' : 'my-3'}`}>
      <div className='col-auto col-lg-3 d-flex flex-column'>
        <span className='text-ellipsis text-end'>{title}</span>
      </div>
      <div className='col px-lg-4 px-xl-5'>
        {!!onChange ? type && ['radio'].includes(type) && choices && choices.length > 0 ? (
          <div className='d-flex'>
            {value !== '' && choices.map((choice) => (
              <div key={choice.value} className='form-check form-check-inline'>
                <label className='form-check-label' role="button">
                  <input
                    className='form-check-input'
                    type={type}
                    value={choice.value}
                    checked={value === choice.value}
                    onChange={onChange}
                    disabled={disabled}
                    {...props}
                  />{choice.label}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <input className='form-control' value={value} onChange={onChange} disabled={disabled} {...props} />
        ) : (
          <div className='text-primary ps-2' style={{ wordWrap: 'break-word' }}><b>{value}</b></div>
        )}
      </div>
      {(!!onClick || !!onChange) && (
        <div className={`col-auto d-flex align-items-end${!onClick ? ' d-none d-lg-block' : ''}`}>
          <button className='btn btn-sm btn-primary bg-grad-primary rounded-pill px-3 px-md-4 mt-4'
            style={!onClick ? { visibility: 'hidden' } : undefined} onClick={onClick} disabled={disabled}>
            {label ? label : t('変更する')}
          </button>
        </div>
      )}
    </div>
  )
}
