import { MeetingList } from './page/MeetingList/MeetingList'
import { MemberList } from './page/MemberList/MemberList'
import { Contact } from './page/contact/Contact'
import { Guide } from './page/guide/Guide'
import { Policies } from './page/policies/Policies'
import { Questions } from './page/questions/Questions'
import { Settings } from './page/setting/Settings'
import { SttDictionaries } from './page/setting/component/SttDictionaries'
// import { DeleteAccount } from './page/deleteAccount/DeleteAccount'
import { Subscribe } from './page/subscribeForm/Subscribe'
import { PrivacyPolicy } from './page/policies/PrivacyPolicy'
import { Share } from './page/share/Share';
import { ComponentType } from 'react';
import { Advisor } from './page/advisor/Advisor';
import { AdminPage } from './page/admin/AdminPage'

export const BASE_ROUTES = {
  LIST: '/',
  SETTING: '/settings',
  SETTING_STT_DICTIONARIES: '/settings/sttDictionaries',
  GUIDE: '/guide',
  CONTACT: '/contact',
  // DELETE_ACCOUNT: '/delete-account',
  QUESTIONS: '/questions',
  POLICIES: '/policies',
  PRIVACY_POLICY: '/privacy',
  MEMBERLIST: '/members',
  SUBSCRIBE:  '/subscribe',
  ADVISOR:  '/advisor',
  SHARE:  '/share/:token',
  ADMIN: '/admin',
}

interface RouteInfo {
  path: string
  component: ComponentType<any>;
  auth?: boolean
  exact?: boolean
  requiresNotificationsAndMeetings?: boolean
}

const Routes: RouteInfo[] = [
  {
    path: BASE_ROUTES.SETTING,
    component: Settings,
    exact: true,
  },
  {
    path: BASE_ROUTES.SETTING_STT_DICTIONARIES,
    component: SttDictionaries,
    exact: true,
  },
  {
    path: BASE_ROUTES.SUBSCRIBE,
    component: Subscribe,
    exact: true,
  },
  {
    path: BASE_ROUTES.GUIDE,
    component: Guide,
    exact: true,
  },
  {
    path: BASE_ROUTES.CONTACT,
    component: Contact,
    exact: true,
  },
  // Currently, deleting account is disabled.
  // {
  //   path: BASE_ROUTES.DELETE_ACCOUNT,
  //   component: DeleteAccount,
  //   exact: true,
  // },
  {
    path: BASE_ROUTES.QUESTIONS,
    component: Questions,
    exact: true,
  },
  {
    path: BASE_ROUTES.POLICIES,
    component: Policies,
    exact: true,
  },
  {
    path: BASE_ROUTES.MEMBERLIST,
    component: MemberList,
    exact: true,
  },
  {
    path: BASE_ROUTES.PRIVACY_POLICY,
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: BASE_ROUTES.ADVISOR,
    component: Advisor,
    exact: true,
  },
  {
    path: BASE_ROUTES.SHARE,
    component: Share,
    requiresNotificationsAndMeetings: true,
    exact: true,
  },
  {
    path: BASE_ROUTES.ADMIN,
    component: AdminPage,
    exact: false,
  },
  {
    // this need to be placed last
    path: BASE_ROUTES.LIST,
    component: MeetingList,
    requiresNotificationsAndMeetings: true,
    exact: false,
  },
]

export default Routes
