import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PlanItem } from '../subscribeForm/component/PlanItem'
import UserInfo, { FREE_PLAN_PRODUCT_CODE, type TPlan } from '../../store/UserInfo'

export enum PlanSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

type PlanSelectionProps = JSX.IntrinsicElements['div'] & {
  plans: Array<TPlan>
  order?: PlanSortOrder
  separateFreePlan?: boolean
  onPlanSelect: (a: string) => void
}

/**
 * Compact plan selection only used for user register page. It differs from PlanSelection component of Subscribe
 * page in some aspects so I copied the old component instead of modifying old component with risk of bugs.
 * Also there is a possibility that UI for this component will be changed later.
 */
export const PlanSelection = observer(({
  plans,
  order = PlanSortOrder.DESC,
  separateFreePlan = false,
  onPlanSelect,
}: PlanSelectionProps) => {
  const { t } = useTranslation()
  const [selectedId, setSelectedId] = useState<string | undefined>()

  const selectingPlan = useMemo(() => {
    let selected: TPlan | undefined
    if (!selectedId) {
      // select FREE plan by default if nothing is selected yet
      selected = plans.find((plan) => FREE_PLAN_PRODUCT_CODE === plan.productCode)
      // if FREE plan is not available (which is the case for some resellers) choose first plan by default
      if (!selected) {
        selected = plans[0]
      }
    } else {
      selected = plans.find((plan) => selectedId === plan.id)
    }
    return selected
  }, [plans, selectedId])

  const handlePlanSelect = useCallback((id: string) => {
    setSelectedId(id)
    onPlanSelect(id)
  }, [onPlanSelect])

  const freePlan = plans.find((p) => FREE_PLAN_PRODUCT_CODE === p.productCode)

  return (
    <div className='container'>
      <h2 className='my-4'>{t('現在選択中のプラン')}</h2>
      <div className='row g-2 m-2'>
        <div className='col-3'>
          <PlanItem p={selectingPlan} children={null} />
        </div>
      </div>

      <h2 className='my-4'>{t('選択可能なプラン一覧')}</h2>
      <div className='row g-2 m-2 mb-4'>
        {separateFreePlan && freePlan && (
          <div className='col-3'>
            <PlanItem p={freePlan}>
              <button
                className='btn btn-outline-secondary card-link'
                type='button' onClick={() => handlePlanSelect(freePlan.id)}
              >
                {t('選択')}
              </button>
            </PlanItem>
          </div>
        )}
      </div>
      <div className='row g-2 m-2'>
        {plans.sort((p1, p2) => {
          // show UNLIMITED plans first
          const p1p = p1.amountJpy + (UserInfo.isUnlimitedPlan(p1) ? 1e6 : 0)
          const p2p = p2.amountJpy + (UserInfo.isUnlimitedPlan(p2) ? 1e6 : 0)
          return order === PlanSortOrder.ASC ? p1p - p2p : p2p - p1p
        }).map(p =>
          (separateFreePlan && FREE_PLAN_PRODUCT_CODE === p.productCode) ? null : (
            <div key={p.id} className='col-3'>
              <PlanItem p={p}>
                <button
                  className='btn btn-outline-secondary card-link'
                  type='button' onClick={() => handlePlanSelect(p.id)}
                >
                  {t('選択')}
                </button>
              </PlanItem>
            </div>
          )
        )}
      </div>
    </div>
  )
})
