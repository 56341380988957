import { observer } from 'mobx-react-lite';
import { Meeting } from '../../store/model/Meeting';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { updateMeeting } from '../../api/MeetingFirebaseApi';
import UserInfo from '../../store/UserInfo';
import { useTranslation } from 'react-i18next';

export const EditTag = observer(({ meeting, isShare, allTags }: {
  meeting: Meeting,
  allTags: string[],
  isShare: boolean,
}) => {
  const { t } = useTranslation()

  const [tags, setTags] = useState<string[]>();
  const [tagListOptions, setTagListOptions] = useState<string[]>([]);

  const tagInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTags([...meeting.tags]);
  }, [meeting.tags]);

  useEffect(() => {
    if (!tags) return;
    const options = allTags.filter(t => !tags.includes(t));
    setTagListOptions([...options]);
  }, [allTags, tags]);

  const deleteTag = useCallback((tag: string) => {
    if (!tags) return;
    let deleteTags = [...tags];
    deleteTags = deleteTags.filter(prevTag => prevTag !== tag);
    setTags(deleteTags);
    updateMeeting(UserInfo.id, meeting.id, {tags: deleteTags}).then(_result => {
      meeting.setData({tags: deleteTags});
    });
  }, [meeting, tags]);

  const addTag = useCallback((tag: string) => {
    if (!tags) return;
    const newTags = [...tags, tag];
    setTags(newTags);
    updateMeeting(UserInfo.id, meeting.id, {tags: newTags}).then(_result => {
      meeting.setData({tags: newTags});
    });
    if (tagInputRef.current) {
      tagInputRef.current.value = '';
    }
  }, [meeting, tags]);

  return (
    <div className="d-flex flex-row flex-wrap">
      {tags && tags.map(tag => (
        <div
          key={tag}
          className="tag badge badge-pill bg-primary">
          <i className="bi bi-tag-fill"></i>
          {tag}
          {!isShare && <i onClick={() => deleteTag(tag)} className="close bi bi-x-circle-fill"></i>}
        </div>
      ))}
      {!isShare && (<div className="input-group tag-form w-25 flex-grow-1">
        <input
          ref={tagInputRef}
          type="text"
          className="tag-form form-control w-25"
          list="tagListOptions"
          placeholder={t('タグを入力...')} />
        <button
          type="button"
          className="btn btn-outline-success"
          onClick={() => tagInputRef.current && tagInputRef.current.value.trim() !== '' && addTag(tagInputRef.current.value)}
        >{t('追加')}</button>
        <datalist id="tagListOptions">
          {tagListOptions.map((tag) => (
            <option key={tag} value={tag} />
          ))}
        </datalist>
      </div>)}
    </div>
  );
});
