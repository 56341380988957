import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

import UserInfo from '../../../store/UserInfo'
import TimeCounter from '../../../view/TimeCounter'
import Api from '../../../api/Api'
import { CANCEL_MEMBER_LIMIT } from '../../../constant/Endpoints'

const memberCountGuard = (count: number) => {
  return count >= 3600 ? Math.floor(count / 3600) : count
}

// column sizes
const LEFT_COL_SIZE = 3
const RIGHT_COL_SIZE = 9

export const UserInfoDisplay = observer(() => {
  const { t } = useTranslation()
  const dateFmt = new Intl.DateTimeFormat('ja')

  const sub = UserInfo.currentSub

  // member count (only for UNLIMITED plans)
  const maxMemCount = memberCountGuard(UserInfo.isUnlimitedPlan() ? (UserInfo.currentSub?.plan.maxUseTime || 0) : 0)
  const currentMemCount = memberCountGuard(UserInfo.isUnlimitedPlan() ? (UserInfo.currentSub?.remainingTime || 0) : 0)

  const handleCancelMemberLimit = useCallback(async () => {
    try {
      const response = await Api.post(CANCEL_MEMBER_LIMIT)
      if (response.data.success) {
        await UserInfo.refreshTime()
      }
    } catch (error) {
      console.warn('Error canceling member limit', error)
    }
  }, [])

  return (sub ? (
    <div className='row mt-2'>
      <div className={`col-${LEFT_COL_SIZE}`}>{t('残り使用時間')}</div>
      <div className={`col-${RIGHT_COL_SIZE}`}>
        <b>
          {UserInfo.isUnlimitedPlan() ? (
            <span className='text-danger'>{t('時間無制限')}</span>
          ) : (
            <TimeCounter second={sub.remainingTime} />
          )}
        </b>
      </div>
      {(sub.plan && !UserInfo.isUnlimitedPlan()) ? (
        <>
          <div className={`col-${LEFT_COL_SIZE}`}>{t('最大時間')}</div>
          <div className={`col-${RIGHT_COL_SIZE}`}>
            <b>
              {UserInfo.isUnlimitedPlan() ? (
                <span className='text-danger'>{t('時間無制限')}</span>
              ) : (
                <TimeCounter second={sub.plan.maxUseTime} />
              )}
            </b>
          </div>
        </>
      ) : null}
      {!!maxMemCount && (
        <>
          <div className={`col-${LEFT_COL_SIZE}`}>{t('メンバー数')}</div>
          <div className={`col-${RIGHT_COL_SIZE}`}>
            <b>
              <span className='text-danger'>
                {maxMemCount}{currentMemCount > maxMemCount ? ` (+${currentMemCount - maxMemCount})` : ''}
              </span>
              {currentMemCount > maxMemCount && (
                <>
                  <br />
                  <div className='btn btn-primary btn-sm rounded-pill my-2' onClick={handleCancelMemberLimit}>
                    {t('メンバー数追加をキャンセル')}
                  </div>
                </>
              )}
            </b>
          </div>
        </>
      )}
      <div className={`col-${LEFT_COL_SIZE}`}>{t('プラン名')}</div>
      <div className={`col-${RIGHT_COL_SIZE} text-primary`}>
        <b>{sub.plan?.name}{sub.plan?.name !== sub.plan?.productCode ? ` (${sub.plan?.productCode})` : ''}</b>
      </div>
      <div className={`offset-${LEFT_COL_SIZE} col-${RIGHT_COL_SIZE} text-primary`}>
        <b>{t('currency.yen', { amount: sub.plan.amountJpy })} / {sub.plan.isMonthly ? t('月') : t('年')}</b>
      </div>
      <div className={`col-${LEFT_COL_SIZE}`}>{t('ステータス')}</div>
      <div className={`col-${RIGHT_COL_SIZE} text-primary`}>
        <b>{sub.isBeingCancelled ? t('キャンセル済') : t('有効')}</b>
      </div>
      <div className={`col-${LEFT_COL_SIZE}`}>
        {sub.isBeingCancelled ? t('終了日') : t('次回決済日')}
      </div>
      <div className={`col-${RIGHT_COL_SIZE} text-primary`}>
        <b>{dateFmt.format(sub.refreshDate)}</b>
      </div>
    </div >
  ) : null)
})
