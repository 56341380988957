import { makeAutoObservable } from 'mobx'
import { orderBy, query } from 'firebase/firestore'
import axios from 'axios'
import { t } from 'i18next'

import Api, { ApiWrapper, setToken } from '../api/Api'
import {
  CHECK_SESSION,
  CONFIRM_EMAIL,
  DELETE_ACCOUNT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  UPDATE_USER,
  USER_CREATE,
  USER_LOGIN,
  USER_LOGOUT,
  DESCRIBE_ME,
  FETCH_MEMBERS,
  SUBSCRIBE_TO_PLAN,
  SEND_AN_INQUIRY,
  UNSUBSCRIBE_FROM_PLAN,
  GET_LAST_CARD_INFO,
  RESUME_SUBSCRIPTION,
  RESEND_EMAIL_CONFIRMATION,
  USER_DATA_VALIDATION,
  USER_LOGIN_FIREBASE
} from '../constant/Endpoints'
import { MeetingStore } from './MeetingStore';
import { Meeting } from './model/Meeting'
import { BUTTON_MODES, show } from '../view/PopupEvent'
import { sentryLog } from '../util/sentry'
import { Auth } from '../firebase'
import { User } from 'firebase/auth'

const TOKEN_STORAGE_KEY = 'clip.token'
const USER_STORAGE_KEY = 'clip.user'

export const PROPERTY_CHAR_LIMIT = 100

export const FREE_PLAN_PRODUCT_CODE = 'FREE'
const UNLIMITED_PLAN_CODE = 'UNLIMITED'
const UNLIMITED_L_PLAN_CODE = 'UNLIMITED_L'

const DONUT_RESELLERS = [
  'donut',
  'metaad',
  'googlead',
]
export const isDonutReseller = (resellerCode: string) => DONUT_RESELLERS.includes(resellerCode)

export type TSubscription = {
  refreshDate: Date,
  remainingTime: number,
  isBeingCancelled: boolean,
  plan: TPlan
}

export type TPlan = {
  id: string,
  name: string,
  amountJpy: number,
  isMonthly: boolean,
  productCode: string,
  maxUseTime: number, // In Seconds.
}

export type TMember = {
  id: string,
  displayName: string,
  email: string,
  description: string,
  isDeleted: boolean,
  isConfirmed: boolean,
}

/**
 * Simple type definition for GPTechReseller record which omits
 * createdAt and updatedAt columns because they won't be used in UI code
 */
export type TReseller = {
  id: string,
  code: string,
  name: string,
}

/**
 * Data for calling user register API.
 */
export type UserRegistrationData = {
  // The email of the user
  email: string
  // The password of the user
  password: string
  // Whether the user is registering as a company
  isCompany?: boolean

  // the fields bellow are exclusive when registering as company, for personal account they are not needed

  // The name of the user
  name?: string
  // The kana representation of the user's name
  nameKana?: string
  // The name of the company
  company?: string
  // The kana representation of the company's name
  companyKana?: string
  // The department of the user within the company
  dept?: string
  // The phone number of the user or company
  phone?: string
  // The address of the company
  addr?: string
  // reseller info
  resellerCode?: string
  resellerName?: string
  userCode?: string
}

/**
 * Detailed information returned from server-side validation.
 */
type ValidationError = {
  param: string
  msg: string
  value: string
  location: string
}

class UserInfoClass {
  // Firebase Authentication support
  fUser?: User | null = undefined
  setFUser(user?: User | null) {
    if (this.fUser === user) { return } // no change

    this.fUser = user
    if (this.fUser && !this.loggedIn) {
      this.fUser.getIdToken().then((idToken) => {
        if (!this.loggedIn) {
          this.loginWithFirebase(idToken)
        }
      })
    }
  }

  loggedIn: boolean | undefined = undefined
  id: number | undefined = undefined
  email = ''
  displayName = ''
  currentSub?: TSubscription = undefined

  reseller?: TReseller
  plans?: TPlan[]

  isManager = false
  isAdmin = false

  meetings?: MeetingStore
  members: Array<TMember> = []

  constructor() {
    // restore session token
    const token = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (token) {
      setToken(token)

      // check session at the start
      this.checkLogin()
    } else {
      this.setLoggedIn(false)
    }

    makeAutoObservable(this)
  }

  get gpTechRemainingMeetingTime(): number {
    if (!this.currentSub) { return 0 }
    return this.currentSub.remainingTime
  }

  isUnlimitedPlan(plan?: TPlan): boolean {
    return !!(plan || this.currentSub?.plan)?.productCode.startsWith(UNLIMITED_PLAN_CODE)
  }

  isUnlimitedLPlan(plan?: TPlan): boolean {
    return (plan || this.currentSub?.plan)?.productCode === UNLIMITED_L_PLAN_CODE;
  }

  setLoggedIn = (loggedIn: boolean, userData?: UserInfoClass) => {
    this.loggedIn = loggedIn
    if (userData !== null) {
      this.updateUserInfo(userData)
    }
    if (loggedIn) {
      this.meetings = new MeetingStore(`users/${this.id}/meetings`, Meeting, true)
      this.meetings.filter = (ref) => {
        return query(ref, orderBy('createdAt', 'desc'))
      }
      this.meetings.onBeforeCreate = (id: string, data: any) => {
        data.owner = this.id
        return data
      }

      this.refreshTime().then(() => this.saveUser())
      if (userData?.isManager) {
        this.fetchMembers()
      }

      const event = new Event('loggedIn');
      window.dispatchEvent(event);
    } else {
      this.meetings = undefined

      // clear saved user
      this.saveUser({})
    }
  }

  private localLogout = () => {
    // clear Firebase Authentication state
    this.setFUser(null)
    Auth.signOut()

    setToken()
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    this.setLoggedIn(false)
  }

  private saveUser = (userData?: any) => {
    if (!userData) {
      userData = {}
      Object.entries(this).forEach(([k, v]) => {
        if (typeof v !== 'function') {
          userData[k] = v
        }
      })
    }
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userData))
  }

  private restoreUser = () => {
    return JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || 'undefined')
  }

  private setData = (key: string, value: string) => {
    if (key in this) {
      (this as any)[key] = value
    }
  }

  private getErrorMessage(response: any, error: any) {
    return response?.message || error?.response?.data?.message
  }

  /**
   * Set UI to logged in state using login data returned from server.
   */
  loginWithUserData(serverResponse: any) {
    const { token } = serverResponse
    // console.log(data.user)

    localStorage.setItem(TOKEN_STORAGE_KEY, token)
    setToken(token)
    // this.saveUser(data.user)
    this.setLoggedIn(true, serverResponse.user)
  }

  logIn = async (email: string, password: string) => {
    try {
      const response = await Api.post(USER_LOGIN, { email, password })
      if (response.status === 200 && response.data.success) {
        this.loginWithUserData(response.data)
      } else {
        throw response.status
      }
    } catch (error: any) {
      // reason
      const reason = error.response?.data?.message
      console.log(reason)

      this.localLogout()
      throw reason
    }
  }

  loginWithFirebase = async (idToken: string) => {
    try {
      const response = await Api.post(USER_LOGIN_FIREBASE, { idToken })
      if (response.status === 200 && response.data.success) {
        this.loginWithUserData(response.data)
      } else {
        throw response.data
      }
    } catch (error: any) {
      // reason
      const reason = error.response?.data?.message || error.message
      console.log('loginWithFirebase', reason)

      this.localLogout()
      throw reason
    }
  }

  updateUserInfo = (userInfo?: UserInfoClass) => {
    if (userInfo?.currentSub?.refreshDate) {
      userInfo.currentSub.refreshDate = new Date(userInfo.currentSub.refreshDate);
    }
    this.id = userInfo?.id
    this.email = userInfo?.email || ''
    this.displayName = userInfo?.displayName || ''
    this.isManager = userInfo?.isManager || false
    this.isAdmin = userInfo?.isAdmin || false
    this.currentSub = userInfo?.currentSub || {
      refreshDate: new Date(),
      remainingTime: 0,
      isBeingCancelled: false,
      plan: {
        id: "",
        name: "プランなし",
        amountJpy: 0,
        isMonthly: false,
        productCode: "NONE",
        maxUseTime: 0,
      }
    }
    this.plans = userInfo?.plans
    this.reseller = userInfo?.reseller
  }

  refreshTime = async () => {
    if (!this.loggedIn) {
      return false
    }

    const resp = await Api.get(DESCRIBE_ME)
    if (resp.data.success) {
      this.updateUserInfo(resp.data.user)
      return true
    }

    return false
  }

  checkLogin = () => {
    Api.get(CHECK_SESSION).then((response) => {
      if (!response.data.success) {
        this.localLogout()
      } else {
        const user = response.data.user || this.restoreUser()
        if (user) {
          this.setLoggedIn(true, user)
        } else {
          this.localLogout()
        }
      }
    }).catch((e) => {
      // log to sentry
      sentryLog(e)
      this.localLogout()
    })
  }

  logOut = () => {
    Api.post(USER_LOGOUT)
    this.localLogout()
  }

  private showUserRegisterError(error: any) {
    const errorInfo: ValidationError[] = error.response?.data?.info || []
    show({
      content: (
        <>
          <p className='text-danger'>{t(error.response?.data?.message || error.message || `${error}`)}</p>
          {errorInfo.length > 0 && (
            errorInfo.map((err) => (
              <p>
                <span className='fw-bold'>{t(`err.${err.param}`)}:</span>
                <span className='ms-2'>{t(err.msg)}</span>
              </p>
            ))
          )}
        </>
      ),
      staticBackdrop: false,
      btnMode: BUTTON_MODES.OK,
    })
  }

  validateData = async (data: UserRegistrationData) => {
    const [response, error] = await ApiWrapper(Api.post(USER_DATA_VALIDATION, data))
    if (!error && response.success) {
      return true
    } else {
      this.showUserRegisterError(error)
      return false
    }
  }

  // plan is optional, if plan === FREE or undefined server will not include plan in email confirmation link and that is normal
  register = async (data: UserRegistrationData, plan?: string, reseller?: string) => {
    const { email, name } = data

    const [response, error] = await ApiWrapper(Api.post(USER_CREATE, {
      ...data,
      email,
      name: (name || '').substring(0, PROPERTY_CHAR_LIMIT),
      plan,
      reseller,
      /* authenOrigin: process.env.REACT_APP_AUTHDOMAIN, TODO: currently unneeded? */
    }))

    if (!error && response.success) {
      show({
        content: t('notification.accountRegistered', { email }), // `${email} に認証メールを送信しました。認証URLからメールアドレスの認証を行ってください。`,
        // content: 'アカウント登録の申請が完了しました。管理人によって認証されるまでしばらくお待ちください。',
        staticBackdrop: false,
        btnMode: BUTTON_MODES.OK,
      })
      return true
    } else {
      this.showUserRegisterError(error)
      return false
    }
  }

  confirmEmail = async (token: string) => {
    const response = await Api.post(CONFIRM_EMAIL, { token })
    // automatic log in after successfully confirming email
    try {
      this.loginWithUserData(response.data)
    } catch (error: any) {
      // log to sentry
      sentryLog(error)
      // ignored
      console.error('automatic sign in error')
    }
    return !!response.data?.success
  }

  forgotPassword = async (email: string) => {
    const response = await Api.post(FORGOT_PASSWORD, { email, authenOrigin: process.env.REACT_APP_AUTHDOMAIN })
    return !!response.data?.success
  }

  resetPassword = async (token: string, password: string) => {
    const response = await Api.post(RESET_PASSWORD, { token, password, authenOrigin: process.env.REACT_APP_AUTHDOMAIN })
    return !!response.data?.success
  }

  update = async (data: {
    displayName?: string;
  }) => {
    const [response, error] = await ApiWrapper(Api.put(UPDATE_USER, data))

    if (!error && response.success) {
      if (data.displayName) {
        this.setData('displayName', data.displayName.substring(0, PROPERTY_CHAR_LIMIT))
      }
      this.saveUser()

      return true
    }

    return false
  }

  updatePassword = async (
    data: {
      oldPassword: string;
      newPassword: string;
    }
  ) => {
    const [response, error] = await ApiWrapper(Api.put(UPDATE_PASSWORD, data))
    if (!error && response.success) {
      return true
    } else {
      throw this.getErrorMessage(response, error)
    }
  }

  updateEmail = async (
    data: {
      email: string;
      password: string;
    }
  ) => {
    const [response, error] = await ApiWrapper(Api.put(UPDATE_EMAIL, {
      email: data.email.substring(0, PROPERTY_CHAR_LIMIT),
      password: data.password,
    }))
    if (!error && response.success) {
      return true
    } else {
      throw this.getErrorMessage(response, error)
    }
  }

  resendConfirmRequest = async (email: string) => {
    const [response, error] = await ApiWrapper(Api.post(RESEND_EMAIL_CONFIRMATION, {
      email,
      authenOrigin: process.env.REACT_APP_AUTHDOMAIN
    }))
    return [response?.success ?? false, error]
  }

  deleteAccount = async () => {
    const [response, error] = await ApiWrapper(Api.post(DELETE_ACCOUNT))

    if (!error && response.success) {
      this.localLogout()
      return true
    }

    return false
  }

  private _setMember = (mem: Array<TMember>) => {
    this.members = mem
  }

  fetchMembers = async () => {
    const result = await Api.get(FETCH_MEMBERS)
    if (result.data?.success) {
      const data = result.data.members
      this._setMember(data)
    } else {
      this._setMember([])
    }
  }

  /* NOTE:
   * 2024-03-15
   * This should be called sparingly, since the endpoint is supposed to
   * send a query to third-party payment system for now.
   * Do not call it often.
   */
  getLastUsedCardInfo = async () => {
    if (!this.isManager) {
      return [false, null]
    }

    try {
      const result = await Api.get(GET_LAST_CARD_INFO)
      console.log(result)
      return [true, result.data.data]
    } catch (e: any) {
      // log to sentry
      sentryLog(e)
      if (axios.isAxiosError(e)) {
        const isOk = e.response?.data?.success ?? false
        const msg = e.response?.data?.message ?? ""
        return [isOk, msg]
      }
      throw e
    }
  }

  /* TODO:
   * ApiWrapper does this try-catch already.
   * replace it when have time.
  * */
  subscribe = async (planId: string, cardToken?: string) => {
    try {
      await Api.post(SUBSCRIBE_TO_PLAN, { planId, cardToken })
      this.localLogout()
      return [true, '']
    } catch (e: any) {
      // log to sentry
      sentryLog(e)
      if (axios.isAxiosError(e)) {
        const isOk = e.response?.data?.success ?? false
        const msg = e.response?.data?.message ?? ""
        return [isOk, msg]
      }
      throw e
    }
  }

  resumeSubscription = async () => {
    try {
      await Api.post(RESUME_SUBSCRIPTION)
      this.localLogout()
      return [true, '']
    } catch (e: any) {
      // log to sentry
      sentryLog(e)
      if (axios.isAxiosError(e)) {
        const isOk = e.response?.data?.success ?? false
        const msg = e.response?.data?.message ?? ""
        return [isOk, msg]
      }
      throw e
    }
  }

  unsubscribe = async () => {
    try {
      await Api.post(UNSUBSCRIBE_FROM_PLAN)
      this.localLogout()
      return [true, '']
    } catch (e: any) {
      // log to sentry
      sentryLog(e)
      if (axios.isAxiosError(e)) {
        const isOk = e.response?.data?.success ?? false
        const msg = e.response?.data?.message ?? ""
        return [isOk, msg]
      }
      throw e
    }
  }

  sendContactMessage = async (email: string, name: string, category: string, content: string) => {
    const [response, error] = await ApiWrapper(Api.post(SEND_AN_INQUIRY, {
      email, name, category, content
    }))
    if (!error && response.success) {
      return true
    } else {
      this.showUserRegisterError(error)
      return false
    }
  }
}

const UserInfo = new UserInfoClass()
export default UserInfo
